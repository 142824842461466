import React from 'react'
import BaseComponent, { errorMap } from './BaseComponent'
import Logo from "../../img/bat-logo-white.svg"

export default class LoginPage extends BaseComponent {

  constructor(props) {
    super(props)
    this.state = { login: "", pass: "", otp: "", loginError: false, passError: false, otpError: false, error: false, anim: false}
    this._bind('handleInputChange', 'checkLogin', 'processLogin', 'onPressEnter')
  }

  componentDidMount() {
  	document.addEventListener("keyup", this.onPressEnter )
    setTimeout(() => { this.setState({ anim: true }) }, 200)

    document.getElementById('login').focus()
  }

  componentWillUnmount() {
    document.removeEventListener("keyup", this.onPressEnter )
  }

  onPressEnter(event) {
    event.preventDefault()
    if (event.keyCode === 13) {
      document.getElementById("button").click()
    }
  }

  handleInputChange(event) {
	const target = event.target
	let value = target.type === 'checkbox' ? target.checked : target.value
	let name = target.name

	this.setState({
	  [name]: value
	})

	if (name === "login" && name !== "") {
		this.setState({ loginError: false })
	}

	if (name === "pass" && name !== "") {
		this.setState({ passError: false })
	}

  if (name === "otp" && name !== "") {
		this.setState({ otpError: false })
	}
  }

  checkLogin() {
  	if (this.state.login === "") {
  		document.getElementById('login').focus()
  		this.setState({ loginError: true })
  	}

  	if (this.state.login !== "" && this.state.pass === "") {
  		document.getElementById('pass').focus()
  		this.setState({ passError: true })
  	}

  	if (this.state.login !== "" && this.state.pass !== "") {
  		this.processLogin()
  	}
  }

  processLogin() {
    let data  = {
      otp: this.state.otp 
    }
    
    fetch(this.props.API+"/auth/authorize?scope=admin", {
      method: "POST",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        "Authorization": `Basic ${btoa(this.state.login+":"+this.state.pass)}`
      },
      body: JSON.stringify(data)
    })
    .then((response) => {
        return response.json()
    })
    .then((result) => {
      if (!result.status.success) {
        window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "red", 'content' : errorMap(result.data?.error) }}), true);
      } else {
        window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "green", 'content' : "Login successfull."}}), true);
      }
      if (result.status.success === true) {
        sessionStorage.setItem('token', result.data.token.uuid)
        this.props.loginSuccess(result.data)
      } else {
        this.setState({ error: true, login: "", pass: "", otp: "" })
        document.getElementById('login').focus()
      }
    }, (error) => {
      console.log(error)
      window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "red", 'content' : "Something went wrong."}}), true);
    })
  }

  render() {
    return (
      <section className="login">
        <div className={ this.state.anim ? "login-box show" : "login-box" }>
          <img className="login-logo" src={ Logo } alt="BAT Logo"/>
        	<h3>Sign in</h3>
        	<div className="inputs">
        		{ this.state.error ? <p className="error">Wrong credentials.</p> : null }
        		<input type="text" name="login" id="login" placeholder="User name" value={ this.state.login } onChange={ this.handleInputChange } required/>
        		{ this.state.loginError ? <p className="error">Enter user name</p> : null }
        		<input type="password" name="pass" id="pass" placeholder="Password" value={ this.state.pass } onChange={ this.handleInputChange } required/>
        		{ this.state.passError ? <p className="error">Enter password</p> : null }
            <input type="text" minLength={6} maxLength={6} name="otp" id="otp" placeholder="OTP Code" value={ this.state.otp } onChange={ this.handleInputChange } required/>
        		{ this.state.otpError ? <p className="error">Enter password</p> : null }
        		<div className="btn-holder">
        			<button className="btn btn-login" id="button" onClick={ this.checkLogin }><span>Login</span></button>
        		</div>
        	</div>
        </div>
      </section>
    )
  }
}
